import { config } from "config";
import { SetCarPricesPayload, CarPriceState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const default_page_size = config.PAGE_SIZE;
const initialState: CarPriceState = {
  count: 0,
  carPrices: [],
  carPrice: null,
  loading: true,
  current_filters: {},
  carPriceOptions: [],
  filters: { page: 1, page_size: default_page_size },
};

export const carPriceSlice = createSlice({
  name: "carPrice",
  initialState,
  reducers: {
    clear: (state) => {
      state.carPriceOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addCarPrice: (state, action) => {
      state.carPrices.unshift(action.payload);
    },
    updateCarPrice: (state, action) => {
      const { id, carPrice } = action.payload;
      state.carPrices.every(({ _id }, i) => {
        if (id === _id) {
          state.carPrices[i] = carPrice;
          return false;
        }
        return true;
      });
    },
    deleteCarPriceById: (state, action) => {
      const id = action.payload;
      state.carPrices.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.carPrices.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setCarPrice: (state, action) => {
      state.carPrice = action.payload;
    },
    setCarPrices: (state, action: PayloadAction<SetCarPricesPayload>) => {
      const { count, carPrices } = action.payload;
      let options: SelectOption[] = [];

      carPrices.forEach(({ _id, name }: any) => {
        const option = { value: _id, label: `${name}` };
        options.push(option);
      });

      state.count = count;
      state.carPrices = carPrices;
      state.carPriceOptions = options
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.filters.page = action.payload;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const carPriceReducer = carPriceSlice.reducer;

export const carPriceActions = carPriceSlice.actions;
export default carPriceReducer;
