import { config } from "config";
import { SetCarCategoriesPayload, CarCategoryState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const default_page_size = config.PAGE_SIZE;
const initialState: CarCategoryState = {
  count: 0,
  carCategories: [],
  carCategory: null,
  loading: true,
  carCategoryOptions: [],
  current_filters: {},
  filters: { page: 1, page_size: default_page_size },
};

export const carCategorySlice = createSlice({
  name: "carCategory",
  initialState,
  reducers: {
    clear: (state) => {
      state.carCategoryOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addCarCategory: (state, action) => {
      state.carCategories.unshift(action.payload);
    },
    updateCarCategory: (state, action) => {
      const { id, carCategory } = action.payload;
      state.carCategories.every(({ _id }, i) => {
        if (id === _id) {
          state.carCategories[i] = carCategory;
          return false;
        }
        return true;
      });
    },
    deleteCarCategoryById: (state, action) => {
      const id = action.payload;
      state.carCategories.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.carCategories.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setCarCategory: (state, action) => {
      state.carCategory = action.payload;
    },
    setCarCategories: (state, action: PayloadAction<SetCarCategoriesPayload>) => {
      const { count, carCategories } = action.payload;
      let options: SelectOption[] = [];

      carCategories.forEach(({ _id, name }: any) => {
        const option = { value: _id, label: `${name}` };
        options.push(option);
      });

      state.count = count;
      state.carCategories = carCategories;
      state.carCategoryOptions = options;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.filters.page = action.payload;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const carCategoryReducer = carCategorySlice.reducer;

export const carCategoryActions = carCategorySlice.actions;
export default carCategoryReducer;
