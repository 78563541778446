import { config } from "config";
import { SetExtraModelsPayload, ExtraModelState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const default_page_size = config.PAGE_SIZE;
const initialState: ExtraModelState = {
  count: 0,
  extraModels: [],
  extraModel: null,
  loading: true,
  current_filters: {},
  extraModelOptions: [],
  filters: { page: 1, page_size: default_page_size },
};

export const extraModelSlice = createSlice({
  name: "extraModel",
  initialState,
  reducers: {
    clear: (state) => {
      state.extraModelOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addExtraModel: (state, action) => {
      state.extraModels.unshift(action.payload);
    },
    updateExtraModel: (state, action) => {
      const { id, extraModel } = action.payload;
      state.extraModels.every(({ _id }, i) => {
        if (id === _id) {
          state.extraModels[i] = extraModel;
          return false;
        }
        return true;
      });
    },
    deleteExtraModelById: (state, action) => {
      const id = action.payload;
      state.extraModels.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.extraModels.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setExtraModel: (state, action) => {
      state.extraModel = action.payload;
    },
    setExtraModels: (state, action: PayloadAction<SetExtraModelsPayload>) => {
      const { count, extraModels } = action.payload;
      let options: SelectOption[] = [];

      extraModels.forEach(({ _id, name }: any) => {
        const option = { value: _id, label: `${name}` };
        options.push(option);
      });

      state.count = count;
      state.extraModels = extraModels;
      state.extraModelOptions = options
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.filters.page = action.payload;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const extraModelReducer = extraModelSlice.reducer;

export const extraModelActions = extraModelSlice.actions;
export default extraModelReducer;
