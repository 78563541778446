import { config } from "config";
import { SetCarSpecsPayload, CarSpecState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const default_page_size = config.PAGE_SIZE;
const initialState: CarSpecState = {
  count: 0,
  carSpecs: [],
  carSpec: null,
  loading: true,
  current_filters: {},
  carSpecOptions: [],
  filters: { page: 1, page_size: default_page_size },
};

export const carSpecSlice = createSlice({
  name: "carSpec",
  initialState,
  reducers: {
    clear: (state) => {
      state.carSpecOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addCarSpec: (state, action) => {
      state.carSpecs.unshift(action.payload);
    },
    updateCarSpec: (state, action) => {
      const { id, carSpec } = action.payload;
      state.carSpecs.every(({ _id }, i) => {
        if (id === _id) {
          state.carSpecs[i] = carSpec;
          return false;
        }
        return true;
      });
    },
    deleteCarSpecById: (state, action) => {
      const id = action.payload;
      state.carSpecs.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.carSpecs.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setCarSpec: (state, action) => {
      state.carSpec = action.payload;
    },
    setCarSpecs: (state, action: PayloadAction<SetCarSpecsPayload>) => {
      const { count, carSpecs } = action.payload;
      let options: SelectOption[] = [];

      carSpecs.forEach(({ _id, name }: any) => {
        const option = { value: _id, label: `${name}` };
        options.push(option);
      });

      state.count = count;
      state.carSpecs = carSpecs;
      state.carSpecOptions = options
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.filters.page = action.payload;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const carSpecReducer = carSpecSlice.reducer;

export const carSpecActions = carSpecSlice.actions;
export default carSpecReducer;
