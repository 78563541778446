import { lazy } from "react";
import { MODAL } from "redux/slices/modal";

const HandleStatus = lazy(() => import("./HandleStatus"));
const ChangePassword = lazy(() => import("./ChangePassword"));
const ConfirmTransferPopup = lazy(() => import("./ConfirmTransferPopup"));
const ConfirmationForm = lazy(() => import("./ConfirmationForm"));

export { default } from "./AppModal";

export type ModalMapper = {
	[key in MODAL]: "" | JSX.Element;
};

export const modalMapper: ModalMapper = {
	HANDLE_STATUS: <HandleStatus />,
	CHANGE_PASSWORD: <ChangePassword />,
	CONFIRM_TRANSFER: <ConfirmTransferPopup />,
	CONFIRMATION_FORM: <ConfirmationForm />,
};
