import { lazy, useMemo } from "react";
import {
  AirportShuttleOutlined,
  FactCheckOutlined,
  AccountTreeOutlined,
  TimeToLeaveOutlined,
  SupportAgent,
  AddBoxOutlined,
} from "@mui/icons-material";
import UpcomingOutlinedIcon from "@mui/icons-material/UpcomingOutlined";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import { NavigationItemType } from "./components/navigation-item/types";
// import BookingsTabs from "pages/Bookings/BookingsTabs";
const BookingsTabs = lazy(() => import("pages/Bookings/BookingsTabs"));

export const useNavigationItems = (): NavigationItemType[] => {
  return useMemo(
    () => [
      {
        header: "",
      },
      {
        path: "/dashboard",
        label: "Dashboard",
        icon: (props: any) => <DashboardOutlinedIcon {...props} />,
      },
      {
        path: "/booking",
        label: "Booking",
        icon: (props: any) => <SupportAgent {...props} />,
        items: [
          {
            label: "Upcoming",
            icon: (props: any) => <UpcomingOutlinedIcon {...props} />,
            element: <BookingsTabs />,
          },
          {
            label: "On Going",
            icon: (props: any) => <AirportShuttleOutlined {...props} />,
            element: <BookingsTabs />,
          },
          {
            label: "Past",
            icon: (props: any) => <FactCheckOutlined {...props} />,
            element: <BookingsTabs />,
          },
        ],
      },
      {
        path: "/cars",
        label: "Cars",
        icon: (props: any) => <TimeToLeaveOutlined {...props} />,
      },
      {
        path: "/extras",
        label: "Extras",
        icon: (props: any) => <AddBoxOutlined {...props} />,
      },
      {
        path: "/car-prices",
        label: "Pricing",
        icon: (props: any) => <AccountBalanceWalletOutlinedIcon {...props} />,
      },
      {
        path: "/branches",
        label: "Branches",
        icon: (props: any) => <AccountTreeOutlined {...props} />,
      },
    ],
    []
  );
};
