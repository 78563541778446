import { PricesState, SetPricesPayload } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: PricesState = {
  prices: [],
  price: null,
  loading: true,
};

export const priceSlice = createSlice({
  name: "price",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
		addPrice: (state, action) => {
			state.prices.unshift(action.payload);
		},
    updatePrice: (state, action) => {
      const { id, price } = action.payload;
      state.prices.every(({ _id }, i) => {
        if (id === _id) {
          state.prices[i] = price;
          return false;
        }
        return true;
      });
    },
    deletePriceById: (state, action) => {
      const id = action.payload;
      state.prices.every(({ _id }, i) => {
        if (id === _id) {
          state.prices.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setPrice: (state, action) => {
      state.price = action.payload;
    },
    setPrices: (state, action: PayloadAction<SetPricesPayload>) => {
      const { prices } = action.payload;
      state.prices = prices;
    },
  },
});

const priceReducer = priceSlice.reducer;

export const priceActions = priceSlice.actions;
export default priceReducer;
