import { config } from "config";
import { SetSuppliersPayload, SupplierState } from ".";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SelectOption } from "components/atoms/Select";

const default_page_size = config.PAGE_SIZE;
const initialState: SupplierState = {
  count: 0,
  suppliers: [],
  supplier: null,
  loading: true,
  supplierNames: [],
  supplierOptions: [],
  current_filters: {},
  filters: { page: 1, page_size: default_page_size },
};

export const supplierSlice = createSlice({
  name: "supplier",
  initialState,
  reducers: {
    clear: (state) => {
      state.supplierOptions = [];
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    addSupplier: (state, action) => {
      state.suppliers.unshift(action.payload);
    },
    updateSupplier: (state, action) => {
      const { id, supplier } = action.payload;
      state.suppliers.every(({ _id }, i) => {
        if (id === _id) {
          state.suppliers[i] = supplier;
          return false;
        }
        return true;
      });
    },
    deleteSupplierById: (state, action) => {
      const id = action.payload;
      state.suppliers.every(({ _id }, i) => {
        if (id === _id) {
          state.count -= 1;
          state.suppliers.splice(i, 1);
          return false;
        }
        return true;
      });
    },
    setSupplier: (state, action) => {
      state.supplier = action.payload;
    },
    setSuppliers: (state, action: PayloadAction<SetSuppliersPayload>) => {
      const { count, suppliers } = action.payload;

      let names: SelectOption[] = [];
      let options: SelectOption[] = [];

      suppliers.forEach(({ _id, name }: any) => {
        const option = { value: _id, label: `${name}` };
        options.push(option);
      });

      suppliers.forEach(({ name }: any) => {
        const option = { value: name.toLowerCase(), label: `${name}` };
        names.push(option);
      });

      state.count = count;
      state.suppliers = suppliers;
      state.supplierNames = names;
      state.supplierOptions = options;
    },
    setPage: (state, action: PayloadAction<number>) => {
      state.filters.page = action.payload;
    },
    resetPage: (state) => {
      state.filters.page = 1;
    },
    setFilters: (state, action) => {
      state.filters = action.payload;
    },
    setCurrentFilters: (state, action) => {
      state.current_filters = action.payload;
    },
    resetFilters: (state) => {
      state.filters = initialState.filters;
      state.current_filters = initialState.current_filters;
    },
  },
});

const supplierReducer = supplierSlice.reducer;

export const supplierActions = supplierSlice.actions;
export default supplierReducer;
